

import React, { Component } from 'react'
import { Upload, message, Icon } from 'antd'

import { beforeUploadImg } from './../apis/common'
import { connect } from 'react-redux';
import actions from '../store/actions';


import '../styles/common.less';

import { authorApprove, getPhoneVerify } from './../apis/getData'
import { baseURL } from './../configs/config'
import Steps from "./Steps";
const UPLOAD_URL = baseURL + '/upload/upload';


let countdown = 60;
class step2_ extends Component {
  // 初始化页面常量 绑定事件方法
  constructor(props, context) {
    super(props)
    this.state = {
      isLogin: true,
      isImg: false,
      loading: false,
      verifyLabel: '获取验证码',
      formData: {
        code: "",
        // idCard: "",
        phoneNum: "",
        realName: '',
        // imgUrl: [],
      }
    }
  }
  handleChange(value, e) {
    let data = Object.assign({}, this.state.formData)
    switch (value) {
      case 'realName': data = Object.assign({}, this.state.formData, { realName: e.target.value }); break;
      case 'code': data = Object.assign({}, this.state.formData, { code: e.target.value }); break;
      case 'idCard': data = Object.assign({}, this.state.formData, { idCard: e.target.value }); break;
      case 'phoneNum': data = Object.assign({}, this.state.formData, { phoneNum: e.target.value }); break;
      default: this.setState({ formData: data });
    }
    this.setState({ formData: data })
  }
  stempFuc = async () => {
    for (let key in this.state.formData) {
      let val = this.state.formData[key]
      if (!val.length || val.length <= 0) {
        message.error('请完善必填信息后提交');
        return;
      }
    }
    if (!this.isPoneAvailable(this.state.formData.phoneNum)) {
      message.error('请输入正确的手机号');
      return;
    }
    // if (!this.isidCardAvailable(this.state.formData.idCard)) {
    //   message.error('请输入正确的身份证号');
    //   return;
    // }
    if (this.state.formData.realName.length>4) {
      message.error('真实姓名不能超过4个字符');
      return;
    }

    let res = await authorApprove(this.state.formData)

    console.log(res.errno);
    
    if (res.errno === 200) {
      this.props.updateConName('step3')
    } else if (res.errno === 10003) {
      message.error(res.msg);
      this.props.updateLoginStatus({});
      this.props.history.push('login');
    } else {
      message.error(res.msg);
    }
  }
  imgUploadChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      this.setState({ isImg: true });
      return;
    }
    if (info.file.status === 'done') {
      let imgUrl = info.file.response.data.url;
      let data = Object.assign({}, this.state.formData, { imgUrl: [imgUrl] })
      console.log(data);
      this.setState({ formData: data })
      this.setState({ loading: false, })
    }
  };
  isPoneAvailable(val) {
    var myreg = /^[1][3-9][0-9]{9}$/;
    if (!myreg.test(val)) {
      return false;
    } else {
      return true;
    }
  }
  isidCardAvailable(idcode) {
    // 加权因子
    var weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
    // 校验码
    var check_code = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];

    var code = idcode + "";
    var last = idcode[17];//最后一位

    var seventeen = code.substring(0, 17);

    // ISO 7064:1983.MOD 11-2
    // 判断最后一位校验码是否正确
    var arr = seventeen.split("");
    var len = arr.length;
    var num = 0;
    for (var i = 0; i < len; i++) {
      num = num + arr[i] * weight_factor[i];
    }

    // 获取余数
    var resisue = num % 11;
    var last_no = check_code[resisue];

    // 格式的正则
    // 正则思路
    /*
    第一位不可能是0
    第二位到第六位可以是0-9
    第七位到第十位是年份，所以七八位为19或者20
    十一位和十二位是月份，这两位是01-12之间的数值
    十三位和十四位是日期，是从01-31之间的数值
    十五，十六，十七都是数字0-9
    十八位可能是数字0-9，也可能是X
    */
    var idCard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;

    // 判断格式是否正确
    var format = idCard_patter.test(idcode);

    // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
    return last === last_no && format ? true : false;

  }
  getVerifyCode = async () => {
    if (this.state.formData.phoneNum.length === 0) {
      message.error('手机号不能为空');
      return;
    }
    if (!this.isPoneAvailable(this.state.formData.phoneNum)) {
      message.error('请输入正确的手机号');
      return;
    }
    if (countdown === 0) {
      this.setState({ verifyLabel: "获取验证码" })
      countdown = 60;
    } else {
      if (countdown === 60) {
        let res = await getPhoneVerify(`${baseURL}/author/sendCode?phoneNum=${this.state.formData.phoneNum}`);
        if (res.errno === 200) {
        } else {
          message.error(res.msg)
        }
      }
      this.setState({ verifyLabel: "重新发送(" + countdown + ")" })
      countdown--;
      setTimeout(() => {
        this.getVerifyCode()
      }, 1000)
    }
  }
  render() {

    // const imageUrl = this.state.formData.imgUrl[0];
    const uploadButton = (
      <div className='imgUploadText'>
        <i></i>
        <span>请手持身份证上传免冠照片</span>
      </div>
    );
    return (
      <div className="step-wrap">
        <Steps active={2}/>

        <div className='approveStep2'>
          <div>
            <p className='form-item'>真实姓名 : &nbsp; &nbsp;<input type='text' value={this.state.realName} onChange={this.handleChange.bind(this, 'realName')} /></p>
            <p className='form-item'>联系方式 : &nbsp; &nbsp;<input type='text' value={this.state.phoneNum} onChange={this.handleChange.bind(this, 'phoneNum')} /></p>
            <p className='verifyCode form-item'> 验证码  &nbsp; :  &nbsp; &nbsp;&nbsp;
              <input type='text' value={this.state.code} onChange={this.handleChange.bind(this,'code')} />
              <button onClick={this.getVerifyCode}>{this.state.verifyLabel}</button>
            </p>
            {/*<p className='form-item'>身份证号 : &nbsp; &nbsp;<input type='text' value={this.state.idCard} onChange={this.handleChange.bind(this, 'idCard')} /></p>*/}
            {/*<div className='uploadImg'>*/}
            {/*  <span style={{ color: '#242323', fontSize: ".14rem", marginRight: ".2rem" }} className='form-item'>上传图片 : </span>*/}
            {/*  <div className='uploadWrap'>*/}
            {/*    <Upload*/}
            {/*      name="file"*/}
            {/*      className="cover-uploader"*/}
            {/*      showUploadList={false}*/}
            {/*      action={UPLOAD_URL}*/}
            {/*      listType="picture-card"*/}
            {/*      beforeUpload={beforeUploadImg}*/}
            {/*      onChange={this.imgUploadChange}>*/}
            {/*      {this.state.isImg ? (this.state.loading ?*/}
            {/*        <Icon type="loading" style={{ fontSize: 24 }} tip='' /> : <img src={imageUrl} alt="cover" style={{ maxWidth: '2.4rem', maxHeight: '1.5rem' }} />)*/}
            {/*        : uploadButton}*/}

            {/*    </Upload>*/}
            {/*    <p className='imgP'>只支持.JPG .PNG格式</p>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <button onClick={this.stempFuc} style={{ marginTop: '.28rem' }} className='button'>提交</button>
          </div>
        </div>
      </div>
    )
  }
}


let step2 = connect((state) => ({
  ...state
}), actions)(step2_);

export default step2;