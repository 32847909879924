import React from 'react'
import {HashRouter as Router, Route} from 'react-router-dom';

import login from '../pages/login' 
import verify from '../pages/verify' 
import AuthorizedRoute from '../pages/author' 
import  main from '../pages/main/index' 

// import  {getLoginState} from '../apis/getData' 
// import  {baseURL} from './config'

// const isLogin = async () => {
//   let res_ = await getLoginState(`${baseURL}/auth/verifyQrLogin?code=${sessionStorage.getItem("code")}`)
//     if(res_.errno==200 && res_.data){
//       return true;
//     }
// }


export default () => (
  <Router>
    <Route path="/" exact component={login}/>  
    <Route path="/login" exact component={login}/>    
    <Route path="/verify"  component={verify}/>    
    <AuthorizedRoute path="/main" component={main} />
  </Router>
)
