import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import login from "./login.js";
import { connect } from "react-redux";
import { getLoginState } from "../apis/getData";
import { baseURL } from "../configs/config";
import actions from "../store/actions";
import { createHashHistory } from "history";

const history = createHashHistory();

class verify_ extends Component {
  constructor(props, context) {
    super(props);
  }
  async componentWillMount() {
    let res_ = await getLoginState(
      `${baseURL}/auth/verifyQrLogin?code=${sessionStorage.getItem("code")}`
    );
    if (res_.errno === 200 && res_.data) {
      this.props.updateLoginStatus(res_.data);
      history.push("/main");
    } else {
      history.push("/login");
    }
  }
  render() {
    return <div></div>;
  }
}

let verify = connect(
  (state) => ({
    ...state,
  }),
  actions
)(verify_);

export default verify;
