import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "../store/actions";
import COS from "cos-js-sdk-v5";
import OSS from "ali-oss";
import {
  uploadVedio,
  getCosCredential,
  getOosCredential,
  getTags
} from "./../apis/getData";
import { beforeUploadImg } from "./../apis/common";
// import {  } from './../apis/common'
import { baseURL, isEn } from "./../configs/config";
import { Upload, message, Icon, Progress,Select, Input } from "antd";

const { Dragger } = Upload;

const UPLOAD_IMG_URL = baseURL + "/upload/upload";
const UPLOAD_VIDEO_URL = baseURL + "/upload/uploadVideo";

// function debounce(fn, wait) {
//   var timeout = null;
//   return function() {
//       if(timeout !== null)
//               clearTimeout(timeout);
//       timeout = setTimeout(fn, wait);
//   }
// }
function randomWord(randomFlag, min, max) {
  let str = "",
    range = min,
    arr = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];

  if (randomFlag) {
    range = Math.round(Math.random() * (max - min)) + min; // 任意长度
  }
  for (let i = 0; i < range; i++) {
    var pos = Math.round(Math.random() * (arr.length - 1));
    str += arr[pos];
  }
  return str;
}

class EditVedio_ extends Component {
  // 初始化页面常量 绑定事件方法
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      loading: true,
      isImg: false,
      isPost: true,
      fileList: [],
      formData: {
        "imgUrl": [],
        'secondTipIds': [],
        'tipIds':'',
        "resourceCode": "",
        "title": "",
        "videoUrl": "",
      },
      videoUrl: "",
      percent: 0,
      props: {
        name: "file",
        // action: UPLOAD_VIDEO_URL,
        onChange: this.videoChangeHandle,
        onRemove: this.removeFile,
        customRequest: this.uploadVedio,
        beforeUpload: this.beforeVideoUpload,
      },
      parentTag:[],
      childsTag:[]
    };
  }
  removeFile = () => {
    this.setState({ fileList: [] });
    this.setState({ videoUrl: "" });
  };
  componentDidMount(){
    this.getTagList()
  }
  getTagList=async (parentId)=>{
    let res_ = await getTags(
      `${baseURL}/topic/select?parentId=${parentId || 0}`
    );
    if (res_.errno === 200 && res_.data) {
      parentId ? this.setState({childsTag:res_.data}) : this.setState({parentTag:res_.data})
    }
  }
  componentWillMount = () => {
    if (this.props.currentRow.resourceCode) {
      let newFormdata = {};
      for (let key in this.props.currentRow) {
        for (let formKey in this.state.formData) {
          if (key === formKey) {
            newFormdata[key] =key == 'tipIds' ?this.props.currentRow[key][0] : this.props.currentRow[key];
          }
        }
      }
      newFormdata.fileSize = this.props.currentRow["fileSize"];
      newFormdata.videoDuration = this.props.currentRow["videoDuration"];
      this.setState({ formData: newFormdata });
      this.setState({ isImg: true });
      this.setState({ videoUrl: this.props.currentRow["videoUrl"] });
    }
  };
  uploadVedio = (params) => {
    // if (isEn) {
    //   //上传至oss
    //   this.uploadOss(params);
    // } else {
    //   this.uploadCos(params);
    // }
    // 上传服务器迁移
    this.uploadOss(params);
  };
  uploadOss = async (params) => {
    let file = params.file;
    this.updateFileSzie(file.size);
    // this.params.fileSize = file.size;
    let res = await getOosCredential(`${baseURL}/upload/ossCredential`);
    let bucket = res.data.ossConfig.bucketName;
    let region = res.data.ossConfig.regionName;
    let dir = res.data.ossConfig.objectDir;
    let resUrl = res.data.ossConfig.accessDomain;
    let name = randomWord(true, 16, 16) + ".mp4";

    // this.videoFileList.push({
    //   name: name,
    //   progress: 0,
    //   upload_status: "text"
    // });
    // var current_index = this.videoFileList.length - 1;
    return new Promise((resolve, reject) => {
      let client = new OSS({
        region: region,
        accessKeyId: res.data.accessKeyId,
        accessKeySecret: res.data.accessKeySecret,
        stsToken: res.data.securityToken,
        secure: true,
        bucket: bucket,
      });
      // resolve(client)
      // let client = await this.createOssClient();
      let that = this;
      client
        .multipartUpload(dir + name, params.file, {
          progress: (progressData) => {
            // debugger;
            console.log(parseInt(progressData));
            that.setState({ percent: parseInt(progressData * 100) });
          },
        })
        .then(
          (val) => {
            if (val.res.statusCode === 200) {
              let url = val.res.requestUrls[0].split("?")[0];
              that.state.formData["videoUrl"] = url;
              that.setState({ videoUrl: url });
              if (url.length > 1) {
                that.setState({
                  fileList: [
                    {
                      status: "done",
                      name: name,
                      type: "video/mp4",
                      uid: "rc-upload-1575271721534-5",
                      url: url,
                    },
                  ],
                });
              }
            } else {
              params.onError("上传失败");
            }
          },
          (err) => {
            params.onError("上传失败");
            // reject(err)
          }
        );
    });
  };
  // uploadCos = async (params) => {
  //   let file = params.file;
  //   this.updateFileSzie(file.size);
  //   let res = await getCosCredential(`${baseURL}/upload/cosCredential`);
  //   // debugger;
  //   let bucket = res.data.cosConfig.bucketName;
  //   let region = res.data.cosConfig.regionName;
  //   let dir = res.data.cosConfig.objectDir;
  //   let resUrl = res.data.cosConfig.accessDomain;
  //   let name = randomWord(true, 16, 16) + ".mp4";

  //   // this.videoFileList.push({
  //   //     name: name,
  //   //     progress: 0,
  //   //     upload_status: "text"
  //   // });

  //   // var current_index = this.videoFileList.length - 1;

  //   var cos = new COS({
  //     // 必选参数
  //     getAuthorization: function (options, callback) {
  //       callback({
  //         TmpSecretId: res.data.credentials.tmpSecretId,
  //         TmpSecretKey: res.data.credentials.tmpSecretKey,
  //         XCosSecurityToken: res.data.credentials.sessionToken,
  //         ExpiredTime: res.data.expiredTime,
  //       });
  //     },
  //     // 可选参数
  //     FileParallelLimit: 1, // 控制文件上传并发数
  //   });
  //   cos.putObject(
  //     {
  //       Bucket: bucket,
  //       Region: region,
  //       Key: dir + name,
  //       Body: file,
  //       ProgressInterval: 1,
  //       onProgress: (progressData) => {
  //         this.setState({ percent: parseInt(progressData.percent * 100) });
  //       },
  //     },
  //     (err, data) => {
  //       if (err) {
  //         message.error(err.error);
  //         // this.videoFileList[current_index].upload_status = "exception";
  //       } else {
  //         if (data.statusCode === 200) {
  //           let url = "https://" + data.Location;
  //           this.state.formData["videoUrl"] = url;
  //           this.setState({ videoUrl: url });
  //           if (url.length > 1) {
  //             this.setState({
  //               fileList: [
  //                 {
  //                   status: "done",
  //                   name: name,
  //                   type: "video/mp4",
  //                   uid: "rc-upload-1575271721534-5",
  //                   url: url,
  //                 },
  //               ],
  //             });
  //           }
  //         }
  //       }
  //     }
  //   );
  // };
  videoChangeHandle = (info, fileList) => {
    // if(this.state.fileList.length<1){
    //   this.setState({ fileList: info.fileList});
    // }
    // if(info.file.status === 'done'){
    //   let res = info.file.response;
    //   if(res.errno === 200){
    //     this.state.formData['videoUrl'] = res.data.url;
    //     this.setState({'videoUrl': res.data.url})
    //   }
    // }
  };
  beforeVideoUpload = (info) => {
    if (this.state.fileList.length >= 1) {
      message.error("仅支持上传一个视频");
      return false;
    }
    if (info.type !== "video/mp4") {
      message.error("仅支持mp4格式的视频");
      return false;
    }
  };
  handleChange = (info) => {
    this.setState({ loading: true });
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      this.setState({ isImg: true });
      return;
    }
    if (info.file.status === "done") {
      let imgUrl = info.file.response.data.url;
      let data = Object.assign({}, this.state.formData, { imgUrl: [imgUrl] });
      this.setState({ formData: data });
      this.setState({ loading: false });
    }
  };
  addEditVedio = async (e) => {
    if (!this.state.isPost) return;
    e.preventDefault();
    for (let key in this.state.formData) {
      let val = this.state.formData[key];
      console.log('key = '+ key,'val = ' + val,val.toString().length);
      if(key == 'tipIds' && !val){
        val=this.parentTag[0].resourceCode
     }
     if(key == 'secondTipIds' && !val){
       val=this.childsTag[0].resourceCode
    }
      if (key !== 'resourceCode'  && (!val.toString().length || val.toString().length <= 0)) {
        message.error("请完善必填信息后提交");
        return;
      }
    }
    this.state.isPost = false;
    let res = await uploadVedio({...this.state.formData,tipIds:[this.state.formData.tipIds]});
    if (res.errno === 200) {
      // this.props.toPage({pageName:'works'})
      this.props.updatePageName("works");
    } else if (res.errno === 10003) {
      message.error(res.msg);
      this.props.updateLoginStatus({});
      this.props.history.push("login");
    } else {
      message.error(res.msg);
    }
  };
  updateFileSzie = (size) => {
    let data = Object.assign({}, this.state.formData, { fileSize: size });
    this.setState({ formData: data });
  };
  updateTitle = (e) => {
    let data = Object.assign({}, this.state.formData, {
      title: e.target.value,
    });
    this.setState({ formData: data });
  };
  changeParentTag=(e)=>{
    let data = Object.assign({}, this.state.formData, {
      tipIds: e,
      secondTipIds:[]
    });
    this.setState({ formData: data });
    this.getTagList(e)
  }
  updateKeyWords = (e) => {
    let data = Object.assign({}, this.state.formData, {
      secondTipIds: e,
    });
    this.setState({ formData: data });
  };
  updateDuration = (e) => {
    let data = Object.assign({}, this.state.formData, {
      videoDuration: e.target.duration,
    });
    this.setState({ formData: data });
  };
  preventEvent(e) {
    e.stopPropagation();
  }
  render() {
    const imageUrl = this.state.formData.imgUrl[0];
    const uploadButton = (
      <div className="imgUploadText">
        <strong></strong>
        <p>上传照片</p>
        <span>支持jpg .png格式</span>
      </div>
    );
    return (
      <div>
        <div className="vedioWrap">
          <form>
            <p className="p form-item">
              视频标题 : &nbsp;
              <Input
                type="text"
                placeholder="请输入视频标题"
                style={{ width: "10.18rem" }}
                value={this.state.formData.title}
                onChange={this.updateTitle}
              />
            </p>
            <p className="p form-item">
                文章标签 : &nbsp;
                <Select
                  type="text"
                  style={{ width: "3.08rem"}}
                  value={this.state.formData.tipIds}
                  onChange={this.changeParentTag}
                  placeholder='请选择标签'
                  maxLength="15"
                  >
                  {this.state.parentTag?.map(item=> <Select.Option value={item.resourceCode}>{item.name}</Select.Option>)}
                </Select>
              </p>
              <p className="p form-item">
              子标签 : &nbsp; &nbsp; &nbsp;
                <Select
                  type="text"
                  style={{ width: "3.08rem"}}
                  value={this.state.formData.secondTipIds}
                  onChange={this.updateKeyWords}
                  mode='multiple'
                  placeholder='请选择子标签'
                  maxLength="15"
                  >
                  {this.state.childsTag?.map(item=> <Select.Option value={item.resourceCode}>{item.name}</Select.Option>)}
                </Select>
              </p>
            <div>
              <div className="uploadVideo">
                <span
                  className="form-item"
                >
                  添加视频 : &nbsp;&nbsp;{" "}
                </span>
                <div className="uploadVideoChild">
                  <Dragger
                    {...this.state.props}
                    className="dragEle"
                    fileList={this.state.fileList}
                  >
                    <strong></strong>
                    <span>点击或将视频拖拽到这里上传</span>
                    <code>支持扩展名：mp4</code>
                    <span className="compressNode">
                      请压缩视频后上传，工具下载地址{" "}
                      <a
                        href="https://maruko.appinn.me/"
                        onClick={(e) => this.preventEvent(e)}
                      >
                        https://maruko.appinn.me/
                      </a>
                    </span>
                  </Dragger>
                  {this.state.percent !== 0 && this.state.percent !== 100 ? (
                    <Progress percent={this.state.percent} size="small" />
                  ) : (
                    ""
                  )}
                </div>

                <video
                  width="100%"
                  height="100%"
                  controls
                  type="video/mp4"
                  src={this.state.videoUrl}
                  onCanPlay={this.updateDuration}
                ></video>
              </div>
            </div>
            <div className="uploadImg" style={{ marginTop: ".6rem" }}>
              <span
                className="form-item"
              >
                上传图片 : &nbsp;&nbsp;{" "}
              </span>
              <div className="uploadWrap">
                <Upload
                  name="file"
                  className="cover-uploader"
                  showUploadList={false}
                  action={UPLOAD_IMG_URL}
                  listType="picture-card"
                  beforeUpload={beforeUploadImg}
                  onChange={this.handleChange}
                >
                  <strong></strong>
                  {this.state.isImg ? (
                    this.state.loading ? (
                      <Icon type="loading" style={{ fontSize: 24 }} tip="" />
                    ) : (
                      <img
                        src={imageUrl}
                        alt="cover"
                        style={{ maxWidth: "3.45rem", maxHeight: "1.9rem" }}
                      />
                    )
                  ) : (
                    uploadButton
                  )}
                </Upload>
                <p className="imgP">仅支持上传1张图片，建议像素：345*190比例</p>
              </div>
            </div>
            <button
              onClick={this.addEditVedio}
              style={{ marginLeft: "3.9rem", marginTop: ".6rem" }}
              className="button"
            >
                确认上传
            </button>
          </form>
        </div>
      </div>
    );
  }
}

let EditVedio = connect(
  (state) => ({
    ...state,
  }),
  actions
)(EditVedio_);

export default EditVedio;
