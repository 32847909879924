// @import '~antd/dist/antd.css';

import React, { Component } from 'react'
import logo from './../images/logo.png'
import avatarDefault from './../images/avatarDefault.png'
import { connect } from 'react-redux';

import { Select } from 'antd'

const { Option } = Select;

class headerCon_ extends Component {
    // 初始化页面常量 绑定事件方法
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentWillMount() {

    }

    handleChange(val) {
        sessionStorage.setItem('language', val);
    }

    // 切换首页与投稿指南
    tabsChange = (val) => {
        this.props.changeTab(val);
    }

    render() {
        const dropClass = {
            backgroundColor: 'transparent',
            color: 'white'
        }
        return (
            <div className='headerWrap'>
                <div className='logoBox'><img src={logo} className='logoImg'/></div>
                <div className="tabs">
                    <div onClick={()=>this.tabsChange('home')}>首页</div>
                    <div onClick={()=>this.tabsChange('guide')}>玩家投稿指南</div>
                </div>
                <div className='div'>
                    <p onClick={this.props.changeShow} style={{ cursor: 'pointer' }}>
                        <img src={this.props.loginInfo.avatar || avatarDefault}/>
                        <span>{this.props.loginInfo.nickname || '登录'}</span>
                    </p>
                    {/* <Select defaultValue="zh_CN"  onChange={this.handleChange}
          className='languageSwitch' 
          dropdownMenuStyle={dropClass}
          defaultActiveFirstOption = {false}
          >  
  
            <Option value="zh_CN" className='optItem'>简体中文</Option>
            <Option value="en_US" className='optItem'>英式英文</Option>
          </Select> */}
                    <a href='https://ac.dragonest.com/' className='toOfficalWeb'>进入官网</a>
                </div>
                <div className="mobileItem">
                    <div className="item ch"></div>
                    <div className="item en"></div>
                    <div className="item user"></div>
                </div>
            </div>
        )
    }
}


let headerCon = connect((state) => ({
    ...state
}),)(headerCon_);

export default headerCon;