// @import '~antd/dist/antd.css';

import React, { Component } from "react";
// import { connect } from 'react-redux'
// import { hashHistory/* , Link */ } from 'react-router'
// import {  Button } from 'antd'
import { getQrCode, getLoginState, getApprovalStatus } from "./../apis/getData";

import HeaderCon from "./../components/HeaderCon";
import bg from "../images/bg_2.png";
import loginImg from "../images/loginBtn_2.png";
import closeImg from "../images/closeBtn.png";
// import qqImg from '../images/qq.png';
// import wechatImg from '../images/wechat.png';
import "./login.css";
import { baseURL } from "../configs/config";

import { connect } from "react-redux";

import actions from "../store/actions";
import GuideIndex from "../components/guide";
import HeaderLayout from "../components/HeaderLayout";
import { Col, Row } from "antd";
import guideIcon from '../images/guide/icon.png';

class login_ extends Component {
  // 初始化页面常量 绑定事件方法
  constructor(props, context) {
    super(props);
    this.state = {
      show: false,
      QRcode: "",
      code: "",
      currentTab:'home',// 当前tab
    };
    this.toLogin = this.toLogin.bind(this);
    this.closeLogin = this.closeLogin.bind(this);
  }

  async componentWillMount() {
    if (sessionStorage.getItem("code")) {
      let res_ = await getLoginState(
        `${baseURL}/auth/verifyQrLogin?code=${sessionStorage.getItem("code")}`
      );
      if (res_.errno === 200 && res_.data) {
        this.props.updateLoginStatus(res_.data);
      }
    }
  }
  closeLogin() {
    this.setState({ show: false });
  }
  async toLogin() {
    this.setState({currentTab: 'home'});
    let code = sessionStorage.getItem("code");
    let that = this;
    if (code) {
      let res_ = await getLoginState(
        `${baseURL}/auth/verifyQrLogin?code=${code}`
      );
      if (res_.errno === 200 && res_.data) {
        that.props.updateLoginStatus(res_.data);
        sessionStorage.setItem("session", res_.data.session);
        this.getApprovalStatus_(res_.data.session);
      } else {
        that.getLoginState_();
      }
    } else {
      that.getLoginState_();
    }
  }
  getLoginState_ = async () => {
    let that = this;
    const res = await getQrCode(`${baseURL}/auth/getQrCodeBase64`);
    if (res.errno === 200) {
      this.setState({ show: true });
      this.setState({ QRcode: res.data.imgBase64 });
      this.setState({ code: res.data.code });
      sessionStorage.setItem("code", res.data.code);
      let loginFor = window.setInterval(async function () {
        let res_ = await getLoginState(
          `${baseURL}/auth/verifyQrLogin?code=${res.data.code}`
        );
        if (res_.errno === 200 && res_.data) {
          that.props.updateLoginStatus(res_.data);
          sessionStorage.setItem("session", res_.data.session);
          that.getApprovalStatus_(res_.data.session);
          clearInterval(loginFor);
        }
      }, 2000);
    }
  };
  getApprovalStatus_ = async (session) => {
    let res = await getApprovalStatus({ session: session });
    if (res.errno === 200) {
      switch (res.data.state) {
        case 0:
          this.props.updateConName("step3");
          break;
        case 1:
          this.props.updateConName("article");
          break;
        case 2:
          this.props.updateConName("step0");
          break;
        case 9:
          this.props.updateConName("step0");
          break;
        default:
          this.props.updateConName("step0");
          break;
      }
      sessionStorage.setItem("authorState", res.data.state);
      this.props.history.push("/main");
    }
  };

  // 首页与投稿指南tab切换
  changeTab = (val)=>{
    this.setState({
      currentTab: val,
    })
  }
  render() {
    // const contentWrap = {
    //   position: "relative",
    //   width: "100%",
    //   height: "91.6vh",
    //   background: `url(${bg})`,
    //   backgroundSize: "100% 100%",
    // };
    // const loginBtn = {
    //   position: "absolute",
    //   width: "3.18rem",
    //   height: "1.31rem",
    //   background: `url(${loginImg})`,
    //   backgroundSize: "100% 100%",
    //   left: "4.57rem",
    //   bottom: "1.25rem",
    //   cursor: "pointer",
    // };
    // const loginDiv = {
    //   backgroundColor: "#fff",
    //   width: "4rem",
    //   // height:"5.56rem",
    //   height: "4.56rem",
    //   position: "absolute",
    //   left: "50%",
    //   top: "50%",
    //   transform: "translateX(-50%) translateY(-50%)",
    //   textAlign: "center",
    // };
    const qrWrap = {
      width: "1.6rem",
      height: "1.6rem",
      marginTop: ".9rem",
      marginBottom: ".2rem",
      marginLeft: "1.2rem",
    };
    const qrStyle = {
      position: 'absolute',
      width: "1.6rem",
      height: "1.6rem",
      left: 0,
      right: 0,
      margin: 'auto'
    };
    const closeBtn = {
      position: "absolute",
      width: ".18rem",
      top: ".2rem",
      right: ".2rem",
      cursor: "pointer",
    };
    let flag = this.state.code;
    const style = {
      checked:{
        color:"#FFB100"
      },
      noChecked:{
        color:"#F2F2F0"
      }
    }
    return (
      <div className="App">
        <HeaderLayout changeShow={this.toLogin}>
          <Col className="header-layout-header-content-item" onClick={()=>this.changeTab('home')} style={ this.state.currentTab === 'home' ? style.checked : style.noChecked}>
            首页
          </Col>
          <Col className="header-layout-header-content-item" onClick={()=>this.changeTab('guide')} style={ this.state.currentTab === 'guide' ? style.checked : style.noChecked}>
            <img src={guideIcon} style={{marginRight:'0.08rem'}}/>玩家投稿指南
          </Col>
        </HeaderLayout>
        {/* <div style={contentWrap}> */}
        {
          this.state.currentTab==='home' ?
              <div className="contentWrap">
                {
                  !this.state.show &&  <div className="loginBtn" onClick={this.toLogin}></div>
                }

                <div id="loginDiv" className={this.state.show ? "show" : "hidden"}>
                  <i>
                    <img src={closeImg} style={closeBtn} onClick={this.closeLogin} />
                  </i>
                  <div className="login-text">扫描二维码登录</div>
                  <div style={qrWrap}>
                    {flag && <img src={this.state.QRcode} style={qrStyle} />}
                  </div>
                  {/*<h5 className="h5Style">扫描二维码登录</h5>*/}
                  <p className="descriptionP">
                    打开掌上龙渊APP<br></br>点击左下角"扫一扫"登录
                  </p>
                  {/* <div className='quickLogin'>
               <i></i>
               <p>快捷登录</p>
               <div>
                 <img src={qqImg}  onClick={this.loginApp}/>
                 <img src={wechatImg}/>
               </div>
             </div> */}
                </div>
              </div>
              :
              <GuideIndex/>

        }

        {/* </div> */}
      </div>
    );
  }
}

let login = connect(
  (state) => ({
    ...state,
  }),
  actions
)(login_);

export default login;
