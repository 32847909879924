import React, { Component } from 'react'


import completeImg from '../images/completeImg.png'

import '../styles/common.less';
import Steps from "./Steps";

export default class Login extends Component {
    // 初始化页面常量 绑定事件方法
    constructor(props, context) {
        super(props)
        this.state = {
            isLogin: true,
        }
    }

    componentWillMount() {
        // console.log(baseURL)
    }

    stempFuc = () => {

        this.props.updateConName({ conName: 'article' })
    }

    render() {
        return (
            <div className="step-wrap">
                <Steps active={3}/>

                <div className='approveStep3'>
                    <div>
                        <img src={completeImg}/>
                    </div>
                    <div className="approveStep3-text">
                        <span>提交成功!</span>
                        <p>请留意短信通知！</p>
                    </div>
                </div>
            </div>
        )
    }
}
