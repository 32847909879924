import {CURRENT_ROW_DATA,PAGE_NAME,IS_LOGIN,CON_NAME} from '../actions/action-type';



//定义默认状态
let initState = {
  currentRow: [
  ],
  pageName:'edit',
  loginInfo:{},
  conName:''
};

function reducer(state=initState,action){
  let newState;
  switch (action.type) {
    case CURRENT_ROW_DATA:
      return Object.assign({}, state, { 
        currentRow: action.currentRow,
      }); 

    case PAGE_NAME:
      return Object.assign({}, state, { 
        pageName: action.pageName
      }); 
    case IS_LOGIN:
      return Object.assign({}, state, { 
        loginInfo: action.loginInfo
      }); 
    case CON_NAME:
      return Object.assign({}, state, { 
        conName: action.conName
      }); 
    default:
      newState = state;
  }
  return newState;
}
export default reducer;