import './../styles/common.less';
import {message} from 'antd'
import logo from './../images/logo.png'

import React, { Component } from 'react'

import {connect} from 'react-redux';
import actions from '../store/actions';

import {Link} from 'react-router-dom';
import { createHashHistory } from 'history';
import { getLogout } from './../apis/getData';

const history = createHashHistory();




class HeadMain_ extends Component {
  // 初始化页面常量 绑定事件方法
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  componentWillMount() {

  }
  updateFirstNav(val){
    let authorState = sessionStorage.getItem('authorState');
    if(authorState !== '1' && val==='works'){
      // message.warning('请先进行作者认证');
    }else{
      this.props.updatePageName(val)
    }
    
  }
  toLoginOUt= async()=>{
    let res = await getLogout()
    if(res.errno == 200){
      this.props.updateLoginStatus({});
      history.push('/login')
    }else{

    }
    
  }
  render() {
    const style = {
      headerWrap:{
        display:'flex',
        alignItems: 'center',
        backgroundColor:"#353F66",
        width:"100%",
        height:"5.2vh",
        padding:'0 3.6rem',
      },
      logoImg:{
        width:"0.86rem",
        marginRight:'1rem'
      },
      checked:{
        color:"#FFB100"
      },
      noChecked:{
        color:"#F2F2F0"
      }
    }
    let pageName = this.props.pageName;
    return (
      <div style={style.headerWrap}>
       <Link to="/login"><img src={logo} style={style.logoImg}/> </Link>
        <div  className='navWrap'>
          <span onClick={this.updateFirstNav.bind(this,'edit')} style={ pageName === 'edit' ? style.checked : style.noChecked}>编辑上传</span>
          <span onClick={this.updateFirstNav.bind(this,'works')} style={pageName === 'works' ? style.checked : style.noChecked}>我的作品</span>
          <span onClick={this.updateFirstNav.bind(this,'app')} style={pageName === 'app' ? style.checked : style.noChecked}>APP下载</span>
        </div>
        <div className='loginAvatar'>
         <p onClick={this.props.changeShow} style={{cursor:'pointer'}}>
            <img src={this.props.loginInfo.avatar} />
            <span>{this.props.loginInfo.nickname}</span>
          </p>
          <span onClick={this.toLoginOUt} className='logoutClass'>[退出]</span>
        </div>
      </div>
    )
  }
}

let HeadMain = connect((state) => ({
  ...state
}),actions)(HeadMain_);

export default HeadMain;