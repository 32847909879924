import React from 'react';
import './Steps.less';


const Steps = (props) => {
    const { active = 1 } = props;
    return (
        <div className="steps">
            <div className="steps-item">
                <div className={`step ${active >= 1 ? 'step-color':''}`}>1</div>
                <span> 选择类型</span>
            </div>
            <div className="steps-hr"/>
            <div className="steps-item">
                <div className={`step ${active >= 2 ? 'step-color':''}`}>2</div>
                <span> 认证资料</span>
            </div>
            <div className="steps-hr"/>
            <div className="steps-item">
                <div className={`step ${active >= 3 ? 'step-color':''}`}>3</div>
                <span> 提交审核</span>
            </div>
        </div>
    )
}

export default Steps;