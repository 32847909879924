import React, { Component } from 'react';
import { Redirect, Route } from "react-router-dom";
import Verify from "./verify.js"
import {connect} from 'react-redux';
import { getLoginState} from './../apis/getData'
import { baseURL } from '../configs/config';
import axios from 'axios'

class AuthorizedRoute_ extends Component {
  constructor(props, context) {
    super(props)
    this.state = {
      isLogin: false,
    }
}
  async componentWillMount() {
  }
  async getLoginState() {
    
  }
   render() {
    
    let { component: Component, ...rest } = this.props; //获取顶层provider上所有的信息
    let isLogin = this.props.loginInfo.userId;
    return (
      <Route {...rest} render={props => {
        return isLogin ? <Component {...this.props} /> : <Verify/>
        // <Redirect to='verify'/> 
      }} />
    )
  }
}

let AuthorizedRoute = connect((state) => ({
  ...state
}))(AuthorizedRoute_);

export default AuthorizedRoute;


