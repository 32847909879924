import React, { Component } from "react";
import { Table, Modal, message, Button } from "antd";
import moment from "moment";

import "../styles/common.less";

import { baseURL } from "../configs/config";

import { getWorks, toPublish, toDelete } from "../apis/getData";

import { connect } from "react-redux";

import actions from "../store/actions";
import banner from "../images/banner.png";
import img from "../images/guide/img.png";
const { confirm } = Modal;

class WorksTables_ extends Component {
  // 初始化页面常量 绑定事件方法
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      pagination: {
        pageSize: 5,
        current: 1,
        marginRight: "20px",
      },
      conName: "step3",
      columns: [
        {
          title: "标题",
          dataIndex: "title",
          key: "title",
          className: "word-wrap",
          width: 180,
        },
        {
          title: "标签",
          dataIndex: "tipNames",
          key: "tipNames",
          className: "word-wrap",
          width: 100,
          render:(val)=>{
            return val?.length ? val[0] : ''
          }
        },
        {
          title: "标签",
          dataIndex: "secondTipNames",
          key: "secondTipNames",
          className: "word-wrap",
          width: 100,
          render:(val)=>{
            return val?.join(',') || ''
          }
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          key: "createTime",
          render: (val) => (
            <span>{moment(val * 1000).format("YYYY-MM-DD HH:mm:ss")}</span>
          ),
          width: 160,
        },
        {
          title: "审核时间",
          dataIndex: "reviewTime",
          key: "reviewTime",
          render: (val) => this.formatTimeStr(val),
          width: 160,
        },
        {
          title: "当前状态",
          dataIndex: "reviewState",
          key: "reviewState",
          render: (val) => this.formatStatus(val),
          width: 90,
        },
        {
          title: "作品影响力",
          dataIndex: "influenceNum",
          key: "influenceNum",
          width: 106,
          // align: 'center'
        },
        {
          title: "操作",
          render: (con, row) => {
            switch (row.reviewState) {
              case 0:
                return (
                  <button
                    className="tableBtn"
                    onClick={(e) => {
                      this.toBackOut(e, row);
                    }}
                  >
                    撤销
                  </button>
                );
              case 1:
                return;
              case 2:
                return (
                  <p style={{ margin: 0 }}>
                    <button
                      className="tableBtn"
                      onClick={(e) => {
                        this.toEditWork(e, row);
                      }}
                    >
                      编辑
                    </button>
                  </p>
                );
              case 3:
                return (
                  <p style={{ margin: 0 }}>
                    <Button
                      className="tableBtn"
                      onClick={(e) => {
                        this.toPublishHandle(e, row);
                      }}
                    >
                      申请发布
                    </Button>
                    <Button
                      className="tableBtn"
                      onClick={(e) => {
                        this.deleteHandle(e, row);
                      }}
                    >
                      删除
                    </Button>
                  </p>
                );
              default:
                return "";
            }
          },
        },
      ],
      tableData: [],
    };
  }
  formatTimeStr(val) {
    if (val === 0) return "";
    else return moment(val * 1000).format("YYYY-MM-DD HH:mm:ss");
  }
  handleTableChange = (pagination, filters, sorter) => {
    console.log(pagination);
    this.setState(
      {
        pagination: pagination,
      },
      () => {
        this.initTable();
      }
    );
  };
  componentWillMount = () => {
    this.initTable();
  };
  async initTable() {
    const data = await getWorks(
      `${baseURL}/topic/my/works?page=${this.state.pagination.current}&size=${this.state.pagination.pageSize}`
    );
    if (data.errno === 200) {
      data.data.list.forEach((ele) => {
        ele.key = ele.resourceCode;
      });
      this.setState({ 
        tableData: data.data.list,
        pagination: {...this.state.pagination, total:data.data.total} 
      });
    }
  }
  toPublishHandle = async (e, row) => {
    let res = await toPublish({
      operate: 1,
      resourceCode: row.resourceCode,
    });
    if (res.errno === 200) {
      this.initTable();
    } else if ((res.errno = 10003)) {
      message.error(res.msg);
      this.props.history.push("login");
    } else {
      message.error(res.msg);
    }
  };
  toEditWork = (e, row) => {
    // this.props.toPage({pageName:'edit'});
    this.props.updatePageName("edit");
    if (row.type === "2") {
      // this.props.setConName({conName:'video'})
      this.props.updateConName("video");
    } else if (row.type === "1") {
      this.props.updateConName("article");
      // this.props.setConName({conName:'article'})
    }

    this.props.initRow(row);
  };
  deleteHandle = async (e, row) => {
    let resourceCode = row.resourceCode;
    confirm({
      title: "确认删除？",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        let res = await toDelete(
          `${baseURL}/topic/delete?resourceCode=${resourceCode}`
        );
        if (res.errno === 200) {
          this.initTable();
          message.success("已删除");
        } else if ((res.errno = 10003)) {
          message.error(res.msg);
          this.props.history.push("login");
        } else {
          message.error(res.msg);
        }
      },
      onCancel() {},
    });
  };
  toSoldOut = async (e, row) => {
    let res = await toPublish({
      operate: 2,
      resourceCode: row.resourceCode,
    });
    if (res.errno === 200) {
      this.initTable();
    } else if ((res.errno = 10003)) {
      message.error(res.msg);
      this.props.history.push("login");
    } else {
      message.error(res.msg);
    }
  };
  toBackOut = async (e, row) => {
    let res = await toPublish({
      operate: 0,
      resourceCode: row.resourceCode,
    });
    if (res.errno === 200) {
      this.initTable();
    } else if ((res.errno = 10003)) {
      message.error(res.msg);
      this.props.history.push("login");
    } else {
      message.error(res.msg);
    }
  };
  formatStatus = (val, ele) => {
    switch (val) {
      case 0:
        return "审核中";
      case 1:
        return "已发布";
      case 2:
        return "审核失败";
      case 3:
        return "草稿 ";
      default:
        return "";
    }
  };
  render() {
    return (
        <div>
          <div>
            <img src={banner} className="guide-banner"/>
          </div>
          <div className="editWrap">
            <Table
                bordered
                rowKey={(record) => record.id}
                dataSource={this.state.tableData}
                columns={this.state.columns}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
            />
          </div>
        </div>

    );
  }
}

let WorksTables = connect(
  (state) => ({
    ...state,
  }),
  actions
)(WorksTables_);

export default WorksTables;
