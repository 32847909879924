

import React, { Component } from 'react'

import '../styles/common.less';

import qrImg from '../images/qrImg.png';          

export default class Login extends Component {
  // 初始化页面常量 绑定事件方法
  constructor(props, context) {
    super(props)
    this.state = {

    }
  }
  componentWillMount(){
    
  }

  render() {
    return (
      <div className='appdownload'>
         <div className='qrWrap'>
          <img src={qrImg}/>
          <p className='node'>苹果/安卓用户扫码下载</p>
          <h3>掌上龙渊</h3>
          <p>多多自走棋官方出品</p>
         </div>
      </div>
    )
  }
}
