import {message} from 'antd'

function beforeUploadImg(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('仅支持jpg或png格式的图片');
    return isJpgOrPng;
  }
  const isLt1M = file.size / 1024 / 1024 < 1;
  if (!isLt1M) {
    message.error('图片需小于1M');
    return isLt1M
  }
}

export {beforeUploadImg,}

