export const set = "set$";
export const brandName = ""; // slogan

let _mockURL = "http://localhost:1111/";
let _baseURL = "";
let _isEn = false;
let hostname = document.location.hostname;
console.log("hostname=" + location.hostname);

switch (hostname) {
  case "zzb-contribute-dev.dragonest.com": // 国内开发服
    _baseURL = "https://zzb-contributeapi-dev.dragonest.com";
    _isEn = false;
    break;
  case "zzb-contribute-qa.dragonest.com": // 国内测试服
    _baseURL = "https://zzb-contributeapi-qa.dragonest.com/";
    _isEn = false;
    break;
  case "zzb-contribute-audit.dragonest.com": // 国内审核服
    _baseURL = "https://zzb-contributeapi-audit.dragonest.com/";
    _isEn = false;
    break;
  case "zzb-contribute.dragonest.com": // 国内正式服
    _baseURL = "https://zzb-contributeapi.dragonest.com";
    _isEn = false;
    break;
  case "zzb-contribute-abroadaudit.dragonest.com": // 海外审核服
    _baseURL = "https://zzb-contributeapi-abroadaudit.dragonest.com";
    _isEn = true;
    break;
  // 开发环境默认配置
  default:
    // 国内测试
    _baseURL = `https://zzb-contributeapi-qa.dragonest.com`;
    // _isEn = false;
    // 国内正式
    // _baseURL = `https://zzb-contributeapi.dragonest.com`;
    _isEn = false;
    // 海外审核
    // _baseURL = "https://zzb-contributeapi-abroadaudit.dragonest.com";
    // _isEn = true;
    break;
}

export const baseURL = _baseURL;
export const isEn = _isEn;
export const mockURL = _mockURL;
