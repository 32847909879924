

import React, { Component } from 'react'
import personApprove from '../images/persion.png'
import teamApprove from '../images/team.png'
import '../styles/common.less';

import {connect} from 'react-redux';
import actions from '../store/actions';
import Steps from "./Steps";

class step1_ extends Component {
  // 初始化页面常量 绑定事件方法
  constructor(props, context) {
    super(props)
    this.state = {
      isLogin: true,
    }
  }
  componentWillMount() {

  }
  stempFuc=()=>{
    this.props.updateConName('step2')
  }
  render() {
    const teamBtn={
      background:"rgba(242,241,239,1)",
      borderRadius:".04rem",
      border:"1px solid rgba(204,203,199,1)",
      color:"rgba(77,74,74,1)"
    }
    return (
      <div className="step-wrap">
        <Steps active={1}/>
        <div className='approveStep1'>
          <div className='approveWrap' style={{marginRight:'0.8rem'}}>
            <div className="approveWrap-img"><img src={personApprove}/></div>
            <div className="approveWrap-content">
              <div className="approveWrap-content-author">个人作者</div>
              <div className="approveWrap-content-des">提供真实的联系方式，真实身份完成认证</div>
              <button onClick={this.stempFuc} className='approveWrap-content-btn'>立即认证</button>
            </div>
          </div>
          <div className='approveWrap' >
            <div className="approveWrap-img"> <img src={teamApprove}/></div>
            <div className="approveWrap-content">
              <div className="approveWrap-content-author">团体机构</div>
              <div className="approveWrap-content-des">提供团体机构材料，运营人真实身份完成认证。</div>
              <button onClick={this.stempFuc} className='approveWrap-content-btn'>请使用个人作者认证</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

let step1 = connect((state) => ({
  ...state
}),actions)(step1_);

export default step1;
