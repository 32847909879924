import React, { useEffect } from 'react';
import { Typography, Row, Col, Anchor, } from 'antd';
import './styles.less';
import banner from '../../images/banner.png';
import img from '../../images/guide/img.png';
import step1 from '../../images/guide/step1.png';
import step2 from '../../images/guide/step2.png';
import step3 from '../../images/guide/step3.png';


const { Paragraph } = Typography;

const GuideIndex = () => {

    const handleClick = (e) => {
        e.preventDefault();
    }

    return (
        <div>
            <div>
                <img src={banner} className="guide-banner"/>
            </div>
            <Row className="guide-container">
                <Col
                    sm={{
                        span: 17,
                        offset: 3,

                    }}
                    xs={{
                        span: 22,
                        offset:2,
                        pull:1
                    }}
                >
                    <div className="containerContent">
                        <Row gutter={[0, 20]}>
                            <Col>
                                <h2 className="title1" id="one">前言</h2>
                                <Paragraph>
                                    <Row>
                                        <Col>
                                            细节！你已发现一篇非常有帮助的指南！
                                        </Col>
                                        <Col>
                                            先稳住激动的心，颤抖的手，根据自己的需求来阅读下面内容吧。
                                        </Col>
                                    </Row>
                                </Paragraph>
                            </Col>
                            <Col>
                                <h2 className="title1">内容分类</h2>
                                <Paragraph underline>
                                    <Row gutter={[0, 16]}>
                                        <Col>
                                            <li>攻略向：强势阵容分析、近期平衡分析、单卡分析、创新阵容分析</li>
                                        </Col>
                                        <Col>
                                            <li>测评向：皮肤棋盘棋手新品测评、新棋子测评</li>
                                        </Col>
                                        <Col>
                                            <li>娱乐向：精彩集锦视频、搞笑沙雕视频、同人文/画</li>
                                        </Col>
                                    </Row>
                                </Paragraph>
                            </Col>
                            <Col>
                                <h2 className="title1">内容要求与建议</h2>
                                <Paragraph>
                                    在这篇稿子即将成型前，需要回答以下几个问题，以清晰自己的目的。我们以上面的内容分类来分别讨论，大家请根据需要撰写的内容来选择性观看。
                                </Paragraph>
                            </Col>
                        </Row>
                        <div className="guide-container-item">
                            <h2 className="title1" id="two">怎样去构思一篇稿子？</h2>
                            <Row gutter={[0, 20]} style={{ marginTop: '14px' }}>
                                <Col>
                                    <h3 className="title2">攻略向</h3>
                                    <Paragraph>
                                        <Row gutter={[0, 16]}>
                                            <Col>
                                                攻略内容的段位门槛是国王及以上，作为教授者的你，需要对游戏有较为深入的理解，以及掌控节奏的大局观。如果尚未达到此标准，可从写分享/讨论/娱乐向的内容开始。
                                            </Col>
                                            <Col>
                                                <li>
                                                    <span className="guide-container-item-label">明确目的：</span>攻略的目的/动机是什么，棋友们能收获什么？<br/>
                                                    <span
                                                        className="guide-container-item-text">这篇攻略能让读者上分/渡劫/猎奇/理解版本…</span>
                                                </li>

                                            </Col>
                                            <Col>
                                                <li>
                                                    <span className="guide-container-item-label"> 适用对象：</span>适用对象：希望面向的人群是哪些？<br/>
                                                    <span
                                                        className="guide-container-item-text">这类人群应该是最需要这篇攻略的，皇后/中高段位/中段位进阶/新手/普适/…</span>
                                                </li>
                                            </Col>
                                            <Col>
                                                <li>
                                                    <span className="guide-container-item-label"> 授予方式：</span>对于读者，我想授之以“鱼”还是“渔”
                                                    ？<br/>
                                                    <span
                                                        className="guide-container-item-text">这将决定整篇内容的重点方向与呈现形式，一图流/细节流/分析流/实战流/研究讨论流/科普流…</span>
                                                </li>
                                            </Col>
                                            <Col>
                                                <li>
                                                    <span className="guide-container-item-label"> 尽量全面：</span>是否考虑相对周全？<br/>
                                                    <div className="guide-container-item-text">1.
                                                        一切的大前提，是游戏当时的平衡环境，考虑是否某些参数有大改，以及能否与目前T0、T1对抗等。
                                                    </div>
                                                    <div className="guide-container-item-text">2.
                                                        在细讲阵容时，需要包含基本要素（上手难易、所需棋子、技能考虑、核心装备、站位、灵活替代、优劣）。
                                                    </div>
                                                </li>
                                            </Col>
                                            <Col>
                                                <li>
                                                <span
                                                    className="guide-container-item-label"> 走向完善：</span>怎样在写稿过程中进步？<br/>
                                                    <div className="guide-container-item-text"> 1. 文章下方留言：读者的反馈是非常真实的</div>
                                                    <div className="guide-container-item-text">2. 社区其他攻略：大佬们的内容总是有可学的</div>
                                                </li>
                                            </Col>
                                        </Row>
                                    </Paragraph>
                                </Col>
                                <Col>
                                    <h3 className="title2">测评向</h3>
                                    <Paragraph>
                                        <Row gutter={[0, 16]}>
                                            <Col>
                                                <li>
                                                    <span className="guide-container-item-label"> 选择理由：</span>为什么选择这件物品/这个棋子来讲？<br/>
                                                    <span
                                                        className="guide-container-item-text">想把这个物品分享给棋友们的原因，是新品/个人喜欢/近期对比/优秀回顾/…</span>
                                                </li>
                                                <br/>

                                            </Col>
                                            <Col>
                                                <li>
                                                <span
                                                    className="guide-container-item-label">展现方式：</span>怎样能突出/佐证文字中描述？<br/>
                                                    <div className="guide-container-item-text">1.
                                                        测评中最有总结性的当属【打分】！无论是总分，还是多维度评分，都可以快速给读者一个印象。
                                                    </div>
                                                    <div
                                                        className="guide-container-item-text">2.测评最直观的方式，依然是【眼见为实】，文字描述之外如果能配合可视化的内容则更能佐证自己所说，GIF/录屏/截图/综合/…
                                                    </div>
                                                </li>
                                            </Col>
                                        </Row>
                                    </Paragraph>
                                </Col>
                                <Col>
                                    <h3 className="title2">娱乐向</h3>
                                    <Paragraph>
                                        <Row gutter={[0, 16]}>
                                            <Col>
                                                <span className="guide-container-item-label">主题看点：</span>内容的主题与看点是什么？<br/>
                                                <div>在让观众/读者细琢磨你的风格前，可以适当从标题/剪辑/画风等展现内容的调调，是搞笑/集锦/苦情文/…。娱乐向内容是最容易成为“系列”的，把控好近/长期的内容走向，让大家慢慢熟悉你的风格吧，说不定能收获一大票死忠催更粉。</div>
                                            </Col>
                                        </Row>
                                    </Paragraph>
                                </Col>
                            </Row>
                        </div>
                        <div className="guide-container-item">
                            <h2 className="title1" id="three">怎样去呈现一篇稿子？</h2>
                            <Paragraph>
                                <Row gutter={[0]}>
                                    <Col>
                                        <li>
                                            <span className="guide-container-item-label">文字表达：</span>内容非常扎实，但是怎么让读者更容易看懂？
                                        </li>

                                    </Col>
                                    <Col>
                                        <div className="guide-container-item-text">
                                            1. 注意文字的表达，与适当的简练，“太长不看”是优秀内容的天敌。
                                        </div>

                                    </Col>
                                    <Col>
                                        <div className="guide-container-item-text">
                                            2.攻略中必不可少的是图，用繁复的文字描述一大段，不如一张图来得直观（如果只保存这张图，或许也能应急一用）。在讲述动态效果时，GIF/视频则是好帮手。
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="guide-container-item-text">
                                            3. 颜色也非常重要，让读者明白主次，“温习”的时候能一下抓住重点。
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={[0, 10]} style={{ marginTop: '10px' }}>
                                    <div className="guide-container-item-text">
                                        <Col>以下为节选一篇优秀的攻略排版示例</Col>
                                        <Col>
                                            <img src={img}/>
                                        </Col>
                                    </div>
                                </Row>
                            </Paragraph>
                        </div>
                        <div className="guide-container-item">
                            <h2 className="title1" id="fore">怎样使用投稿平台？</h2>
                            <Row gutter={[0, 14]}>
                                <Col>
                                    <li>
                                    <span className="title2 guide-container-item-label">
                                        投稿平台的网站链接：
                                         <Typography.Text underline>
                                        <a href="http://zzb-contribute.dragonest.com/">
                                            http://zzb-contribute.dragonest.com/
                                        </a>
                                    </Typography.Text>
                                    </span>
                                    </li>
                                </Col>
                                <Col>
                                    <div className="guide-container-item-text">
                                        Step1. 使用电脑登录投稿平台
                                    </div>

                                </Col>
                                <Col>
                                    <div className="guide-container-item-text">
                                        <img src={step1}/>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="guide-container-item-text">
                                        Step2. 打开掌上自走棋APP点击扫一扫登录
                                    </div>
                                </Col>
                                <Col>
                                    <div className="guide-container-item-text">
                                        <img src={step2}/>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="guide-container-item-text">
                                        Step3. 上传作品，编辑好文章后进入等待发布界面，点击“申请发布”，在经过管理员的审核之后，即可成功发布文章。
                                    </div>
                                </Col>
                                <Col>
                                    <div className="guide-container-item-text">
                                        <img src={step3}/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                <Col
                    sm={{
                        span:3,
                        offset:1
                    }}
                    xs={{
                        span: 23,
                        offset: 1,
                    }}
                >
                    <Anchor
                        affix={true}
                        onClick={handleClick}
                        offsetTop={30}
                        className="guide-anchor"
                    >
                        <Anchor.Link href="#one" title="前言"/>
                        <Anchor.Link href="#two" title="怎样去构思一篇稿子"/>
                        <Anchor.Link href="#three" title="怎样去呈现一篇稿子"/>
                        <Anchor.Link href="#fore" title="怎样使用投稿平台"/>
                        <Anchor.Link href="#five" title="优秀的稿子能换来什么"/>
                    </Anchor>
                </Col>
            </Row>
        </div>


    )

}

export default GuideIndex;