// @import '~antd/dist/antd.css';

import React, { Component } from 'react'

import HeaderMain from './../../components/HeaderMain'
import EditUpload from '../../components/EditUpload'
import WorksTable from '../../components/WorksTable'
import APPDownload from '../../components/APPDownload'


import { getApprovalStatus } from './../../apis/getData'

import { connect } from 'react-redux';
import actions from '../../store/actions';
import './../../styles/common.less';
import HeaderLayout from "../../components/HeaderLayout";
import { Col } from "antd";

class index_ extends Component {
    // 初始化页面常量 绑定事件方法
    constructor(props, context) {
        super(props)
        this.state = {
            show: true,
            pageName: 'edit',
        }
    }

    async componentWillMount() {
        this.props.updateConName('article');
        sessionStorage.setItem('authorState', 1)
        // this.getApprovalStatus_(sessionStorage.getItem('session'));
    }

    getApprovalStatus_ = async (session) => {

        let res = await getApprovalStatus({ session: session });
        if (res.errno === 200) {
            switch (res.data.state) {
                case 0:
                    this.props.updateConName('step3');
                    break;
                case 1:
                    this.props.updateConName('article');
                    break;
                case 2:
                    this.props.updateConName('step0');
                    break;
                case 9:
                    this.props.updateConName('step0');
                    break;
                default:
                    this.props.updateConName('step0');
                    break;
            }
            sessionStorage.setItem('authorState', res.data.state)
            // this.props.history.push('/main');
        } else if (res.errno === 10003) {
            this.props.updateLoginStatus({});
        }
    }
    showPage = () => {
        const pageName = this.props.pageName;
        const conName = this.props.conName;
        switch (pageName) {
            case 'edit':
                return <EditUpload/>;
            case 'works':
                return <WorksTable/>;
            case 'app':
                return <APPDownload/>;
            default:
                return ''
        }
    }

    updateFirstNav(val) {
        let authorState = sessionStorage.getItem('authorState');
        if (authorState !== '1' && val === 'works') {
            // message.warning('请先进行作者认证');
        } else {
            this.props.updatePageName(val)
        }

    }

    render() {
        let pageName = this.props.pageName;
        const style = {
            checked: {
                color: "#FFB100"
            },
            noChecked: {
                color: "#F2F2F0"
            }
        }
        return (
            <div>
                <HeaderLayout updateLoginStatus={this.props.updateLoginStatus}>
                    <Col className="header-layout-header-content-item" onClick={this.updateFirstNav.bind(this, 'edit')}
                         style={pageName === 'edit' ? style.checked : style.noChecked}>
                        编辑上传
                    </Col>
                    <Col className="header-layout-header-content-item" onClick={this.updateFirstNav.bind(this, 'works')}
                         style={pageName === 'works' ? style.checked : style.noChecked}>
                        我的作品
                    </Col>
                    <Col className="header-layout-header-content-item" onClick={this.updateFirstNav.bind(this, 'app')}
                         style={pageName === 'app' ? style.checked : style.noChecked}>
                        APP下载
                    </Col>
                </HeaderLayout>

                <div className='content-wrap'>
                    {this.showPage()}
                </div>
            </div>
        )
    }
}


let index = connect((state) => ({
    ...state
}), actions)(index_);

export default index;