

import React, { Component } from 'react'
import actions from '../store/actions';
import '../styles/common.less';

import {connect} from 'react-redux';
import EditUnLogin from "./EditUnLogin"
import EditVedio from "./EditVedio"

import EditArticle from "./EditArticle"

import ApproveStep1 from "./ApproveStep1"
import ApproveStep2 from "./ApproveStep2"
import ApproveStep3 from "./ApproveStep3"

class EditUpload_ extends Component {
  // 初始化页面常量 绑定事件方法
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  componentWillMount(){

  }
  showCom=()=>{
    let name = this.props.conName;
    switch (name){
      case 'article':
        return <EditArticle/>
      case 'video':
        return <EditVedio/>
      case 'step0':
        return <EditUnLogin />
      case 'step1':
        return <ApproveStep1 />
      case 'step2':
        return <ApproveStep2 />
      case 'step3':
        return <ApproveStep3/>
      default: 
        return ''
    }
  }
  // setConName(conName){
  //   this.props.setConName(conName)
  // }
  switchAV=(val)=>{
    let authorState = sessionStorage.getItem('authorState');
    if(authorState === '1'&& this.props.conName !== val){
      this.props.updateConName(val);
      this.props.initRow({});
    }
  }
  render() {
    const conName = this.props.conName;

    const checked={
      borderBottom:".04rem solid #FFB100"
    }
    const noChecked={
      borderBottom:"none"
    }

    return (
      <div className='editWrap'>
        {
          (['article','video','step0'].includes(conName)) &&(
              <div className='switchAV' >
                <span onClick={this.switchAV.bind(this,'article')} className="uploadSpan" style={conName !=='video' ?checked:noChecked}>上传文章</span>
                <span onClick={this.switchAV.bind(this,'video')} className="uploadSpan" style={conName === 'video' ?checked:noChecked}>上传视频</span>
              </div>
          )
        }

          { this.showCom()}
      </div>
    )
  }
}

let EditUpload = connect((state) => ({
  ...state
}),actions)(EditUpload_);

export default EditUpload;
