import React from 'react';
import './HeaderLayout.less';
import { Row,Col} from 'antd';
import logo from "../images/logo.png";
import { connect } from "react-redux";
import avatarDefault from '../images/avatar-default.png'
import { getLogout } from "../apis/getData";
import { createHashHistory } from 'history';

const history = createHashHistory();

const HeaderLayout = (props) => {

    const toLoginOUt = async()=>{
        let res = await getLogout()
        if(res.errno === 200){
            props.updateLoginStatus({});
            history.push('/login')
        }else{

        }

    }
    return (
        <div className="header-layout-header-wrap">
            <Row type="flex">
                <Col span={4}><img src={logo} className="header-layout-header-logo"/></Col>
                <Col span={13}>
                    <Row type="flex" align="start" justify="start" gutter={48} className="header-layout-header-content">
                        {/*<Col className="header-layout-header-content-item">*/}
                        {/*    首页*/}
                        {/*</Col>*/}
                        {/*<Col className="header-layout-header-content-item">*/}
                        {/*    玩家投稿指南*/}
                        {/*</Col>*/}
                        {
                            props.children
                        }
                    </Row>
                </Col>
                <Col span={5}>
                    <Row type="flex" gutter={10} align="center" className="header-layout-header-login" >
                        <Col>
                            <img width={26} height={26} src={props.loginInfo.avatar || avatarDefault}/>
                        </Col>
                        <Col onClick={props.changeShow}>
                            {props.loginInfo.nickname || '登录'}
                        </Col>
                        {
                            props.loginInfo.nickname && (
                                <Col onClick={toLoginOUt}>
                                    [退出]
                                </Col>
                            )
                        }
                    </Row>
                </Col>
                <Col span={2}>
                    <a href='https://ac.dragonest.com/' className='header-layout-header-content-item-web' >进入官网</a>
                </Col>
            </Row>
        </div>
    )
}


export default connect((state)=>({
    ...state
}))(HeaderLayout);