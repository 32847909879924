import React, { Component } from "react";
import { uploadArticle,getTags } from "./../apis/getData";
import { beforeUploadImg } from "./../apis/common";
import { connect } from "react-redux";
import actions from "../store/actions";
import ReactQuill, { Quill } from "react-quill";

import "react-quill/dist/quill.snow.css";

import { Upload, message, Icon,Select, Input } from "antd";

import { baseURL } from "./../configs/config";
const UPLOAD_URL = baseURL + "/upload/upload";

class EditArticle_ extends Component {
  // 初始化页面常量 绑定事件方法
  constructor(props, context) {
    super(props);
    this.state = {
      isPost: true,
      isLogin: true,
      loading: false,
      isImg: false,
      formData: {
        imgUrl: [],
        secondTipIds: [],
        tipIds:'',
        resourceCode: "",
        title: "",
        content: "",
      },
      parentTag:[],
      childsTag:[]
    };
    this.modules = {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          [{ color: [] }, { background: [] }],
          ["clean"],
        ],
        handlers: {
          image: this.imgHandler,
        },
      },
    };
  }
  imgHandler(state, val) {
    if (state) {
      let fileInput = document.getElementById("imgInput");
      fileInput.click(); // 加一个触发事件
    }
  }
  selectImage() {
    this.refs.uploadInput.click(); //点击modal的html结构中的input标签
  }
  componentDidMount(){
    this.getTagList()
  }
  getTagList=async (parentId)=>{
    let res_ = await getTags(
      `${baseURL}/topic/select?parentId=${parentId || 0}`
    );
    if (res_.errno === 200 && res_.data) {
      parentId ? this.setState({childsTag:res_.data}) : this.setState({parentTag:res_.data})
    }
  }
  componentWillMount() {
    if (this.props.currentRow.resourceCode) {
      let newFormdata = {};
      for (let key in this.props.currentRow) {
        for (let formKey in this.state.formData) {
          if (key === formKey) {
            newFormdata[key] =key == 'tipIds' ?this.props.currentRow[key][0] : this.props.currentRow[key];
          }
        }
      }
      this.setState({ isImg: true });
      this.setState({ formData: newFormdata });
    }
  }
  updateTitle = (e) => {
    let data = Object.assign({}, this.state.formData, {
      title: e.target.value,
    });
    this.setState({ formData: data });
  };
  changeParentTag=(e)=>{
    let data = Object.assign({}, this.state.formData, {
      tipIds: e,
      secondTipIds:[]
    });
    this.setState({ formData: data });
    this.getTagList(e)
  }
  updateKeyWords = (e) => {
    let data = Object.assign({}, this.state.formData, {
      secondTipIds: e,
    });
    this.setState({ formData: data });
  };
  addEditArticle = async (e) => {
    if (!this.state.isPost) return;
    e.preventDefault();
    for (let key in this.state.formData) {
      let val = this.state.formData[key];
      if(key == 'tipIds' && !val){
         val=this.parentTag[0].resourceCode
      }
      if(key == 'secondTipIds' && !val){
        val=this.childsTag[0].resourceCode
     }
      if (key !== "resourceCode" && (!val.length || val.length <= 0)) {
        console.log(key)
        message.error("请完善必填信息后提交");
        return;
      }
    }

    this.state.isPost = false;
    let content = this.state.formData.content
    if(content.includes('img')){
      let imgReg = /<img.*?(?:>|\/>)/gi;
      let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i; // 匹配图片中的src
      let imgs = content.match(imgReg);
      if (!imgs) return;
      if (imgs.length > 0) {
        for (let i = 0; i < imgs.length; i++) {
            let srcHttp = imgs[i].match(srcReg)[1]
            let src=srcHttp.split('?')[0]
            let wh=srcHttp.split('?')[1].split('|')
            let height = wh[1].split('=')[1];
            let width = wh[0].split('=')[1];
            let newImg = `<img src="${
              src
            }" alt="" class="user-defined-img" data-w="${width}" data-ratio="${
                height / width
            }"/>`;
            content = content.replace(
              imgs[i],
              newImg
          );
            
        }
    }
    this.state.formData.content=content
    }
    let res = await uploadArticle({...this.state.formData,tipIds:[this.state.formData.tipIds]});
    if (res.errno === 200) {
      // this.props.toPage({ pageName: 'works' })
      this.props.updatePageName("works");
    } else if (res.errno === 10003) {
      message.error(res.msg);
      this.props.history.push("login");
      this.props.updateLoginStatus({});
    } else {
      message.error(res.msg);
    }
    this.state.isPost = true;
  };
  changeHandle = (info, variate) => {
    if (info.file.status === "done") {
      let res = info.file.response;
      if (res.errno === 200) {
        let url = variate === "imgUrl" ? [res.data.url] : res.data.url;
        this.state.formData[variate] = url;
      }
    }
  };
  coverImgHandle = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      this.setState({ isImg: true });
      return;
    }
    if (info.file.status === "done") {
      let imgUrl = info.file.response.data.url;
      let data = Object.assign({}, this.state.formData, { imgUrl: [imgUrl] });
      this.setState({ formData: data });
      this.setState({ loading: false });
    }
  };
  articleImgHandle = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      let imgUrl = info.file.response.data.url;
      let img = new Image()
      img.src=imgUrl
      img.onload=()=>{
        let quill = this.refs.reactQuill.getEditor(); //获取到编辑器本身
        const cursorPosition = quill.getSelection().index; //获取当前光标位置
        quill.insertEmbed(cursorPosition, "image", `${imgUrl}?width=${img.width}|height=${img.height}`, Quill.sources.USER); //插入图片
        quill.setSelection(cursorPosition + 1); //光标位置加1
      }
      
    }
  };
  articleChange = (value) => {
    let obj = Object.assign(this.state.formData);
    obj.content = value;
    this.setState({ formData: obj });
  };
  render() {
    const articleWrap = {
      width: "4.8rem",
      height: "3.74rem",
    };
    const imageUrl = this.state.formData.imgUrl[0];
    const uploadButton = (
      <div className="imgUploadText">
        {/* <Icon type="upload" /> */}
        <strong></strong>
        <p>上传照片</p>
        <span>支持jpg .png格式</span>
      </div>
    );
    console.log(this.state.loading);
    return (
      <div>
        <div>
          <div className="vedioWrap">
            <div>
              <p className="p form-item">
                文章标题 : &nbsp;
                <Input
                  type="text"
                  placeholder="请输入文章标题"
                  style={{ width: "10.18rem" }}
                  value={this.state.formData.title}
                  onChange={this.updateTitle}
                />
              </p>
              <p className="p form-item">
                文章标签 : &nbsp;
                <Select
                  type="text"
                  style={{ width: "3.08rem" }}
                  value={this.state.formData.tipIds}
                  onChange={this.changeParentTag}
                  placeholder='请选择父标签'
                  maxLength="15"
                  >
                  {this.state.parentTag?.map(item=> <Select.Option value={item.resourceCode}>{item.name}</Select.Option>)}
                </Select>
              </p>
              <p className="p form-item">
                子标签 : &nbsp; &nbsp; &nbsp;
                <Select
                  type="text"
                  style={{ width: "3.08rem" }}
                  value={this.state.formData.secondTipIds}
                  onChange={this.updateKeyWords}
                  mode='multiple'
                  placeholder='请选择子标签'
                  maxLength="15"
                  >
                  {this.state.childsTag?.map(item=> <Select.Option value={item.resourceCode}>{item.name}</Select.Option>)}
                </Select>
              </p>
              <div></div>
              <div className="uploadVideo">
                <span className="form-item">
                  编辑正文 : &nbsp;&nbsp;&nbsp;{" "}
                </span>
                <ReactQuill
                  ref="reactQuill"
                  value={this.state.formData.content}
                  onChange={this.articleChange}
                  style={articleWrap}
                  modules={this.modules}
                />
                <div style={{ zIndex: -1 }}>
                <Upload
                  name="file"
                  showUploadList={false}
                  action={UPLOAD_URL}
                  beforeUpload={beforeUploadImg}
                  onChange={this.articleImgHandle}
                >
                  <button id="imgInput" >点击上传</button>
                </Upload>
                </div>
                <div className="preViewArticle">
                  <span className="proviewSpan">预览</span>
                  <div className="ql-container ql-snow">
                    <div
                      className="ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: this.state.formData.content,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              {/*<div className="article-tip">注意：加入图片时，直接拖拽入文章即可。</div>*/}
              <div className="uploadImg">
                <span className="form-item" >
                  上传图片 : &nbsp;&nbsp;{" "}
                </span>
                <div className="uploadWrap">
                  <Upload
                    name="file"
                    className="cover-uploader"
                    showUploadList={false}
                    action={UPLOAD_URL}
                    listType="picture-card"
                    beforeUpload={beforeUploadImg}
                    onChange={this.coverImgHandle}
                  >
                    {this.state.isImg ? (
                      this.state.loading ? (
                        <Icon type="loading" style={{ fontSize: 24 }} tip="" />
                      ) : (
                        <img
                          src={imageUrl}
                          alt="cover"
                          style={{ maxWidth: "3.45rem", maxHeight: "1.9rem" }}
                        />
                      )
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                  <p className="imgP">
                    仅支持上传1张图片，建议像素：345*190比例
                  </p>
                </div>
              </div>
              <button
                onClick={this.addEditArticle}
                style={{ marginLeft: "3.9rem", marginTop: ".6rem" }}
                className="button"
              >
                确认上传
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let EditArticle = connect(
  (state) => ({
    ...state,
  }),
  actions
)(EditArticle_);

export default EditArticle;
