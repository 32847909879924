

import React, { Component } from 'react'
import authentication from '../images/authentication.png'

import {connect} from 'react-redux';
import actions from '../store/actions';
import {message } from 'antd'

import deal from '../images/deal.png'
import checkedDeal from '../images/dealChecked.png'

class unLogin_ extends Component {
  // 初始化页面常量 绑定事件方法
  constructor(props, context) {
    super(props)
    this.state = {
      isLogin: false,
      dealUrl:deal,
      dealFlag:false
    }
  }
  stempFuc=()=>{
    if(this.state.dealFlag){
      this.props.updateConName('step1')
    }else{
      message.warning('请先阅读并同意用户协议');
    }
  }
  readDeal=()=>{
    if(this.state.dealFlag){
      this.setState({'dealFlag':false})
      this.setState({'dealUrl':deal})
    }else{
      this.setState({'dealFlag':true})
      this.setState({'dealUrl':checkedDeal})
    }
  }
  componentWillMount() {
    // console.log(baseURL)
  }
  render() {
    const button_={
      marginBottom:"0.16rem"
    }
    const dealImg={
        cursor:'pointer',
        width: ".15rem",
        marginBottom: ".04rem"
      
    }
    return (
      <div className="step-wrap">
        <p className='warningP'>严禁上传违规/色情诱惑/低俗等内容，违者封号处理！</p>
        <div className='approveWrap'>
          <p> <img src={authentication}/></p>
          <button onClick={this.stempFuc}  style={button_} className='button'>立即认证</button>
          <p className='deal-wrap'><img src={this.state.dealUrl} onClick={this.readDeal} style={dealImg}/> 认证即同意 <a href='http://www.ilongyuan.com.cn/Index/gameContract.html'>《用户协议》</a><a href='http://www.ilongyuan.com.cn/Index/Privacy.html'>《隐私政策》</a></p>
        </div>
         
      </div>
    )
  }
}


let unLogin = connect((state) => ({
  ...state
}),actions)(unLogin_);

export default unLogin;