import {baseURL, } from '../configs/config';
import {postRequest} from './axios';

export const loginWhite = data => postRequest(`${baseURL}/auth/open/login`,'post',data,) // 登录


export const authorApprove = data => postRequest(`${baseURL}/author/auth`,'post',data,) //作者认证

// export const getPhoneVerify = data => postRequest(`${baseURL}/author/sendCode`,'post',data,) //获取手机验证码
export const getPhoneVerify = url => postRequest(url,'Get') //获取手机验证码

export const getWorks = url => postRequest(url,'Get') // 获取作品列表

export const uploadVedio = data => postRequest(`${baseURL}/topic/addVideo`,'post',data,) // 上传视频

export const uploadArticle = data => postRequest(`${baseURL}/topic/addArticle`,'post',data,) // 上传文章

export const toPublish = data => postRequest(`${baseURL}/topic/operate/works`,'post',data,) // 申请发布

export const toDelete = url => postRequest( url,'DELETE') // 删除作品

export const getQrCode = url => postRequest( url,'GET') // 获取登录二维码

export const getLoginState = url => postRequest( url,'GET') // 获取登录状态


export const getLogout = data => postRequest(`${baseURL}/auth/logout`,'post')  // 登出

export const getApprovalStatus = data => postRequest(`${baseURL}/author/judgePass`,'post')  // 登出

export const getCosCredential = url => postRequest( url,'GET') // 获取cos上传凭证

export const getOosCredential = url => postRequest( url,'GET') // 获取oos上传凭证
export const getTags = url => postRequest( url,'GET') // 获取登录二维码