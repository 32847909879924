//添加待办事项
import {CURRENT_ROW_DATA,PAGE_NAME,IS_LOGIN,CON_NAME} from './action-type';

let actions = {
  initRow: (currentRow) =>{
    return {type: CURRENT_ROW_DATA, currentRow};
  },
  updatePageName:(pageName)=>{
    return {type:PAGE_NAME,pageName}
  },
  updateConName:(conName)=>{
    return {type:CON_NAME,conName}
  },
  updateLoginStatus:(loginInfo)=>{
    return {type:IS_LOGIN,loginInfo}
  }
};

export default actions;//导出ActionCreators